<!--
 * @Author: 熊志伟
 * @Date: 2023-12-06 17:30:02
 * @LastEditors: 熊志伟 xiongzhiwei
 * @LastEditTime: 2025-02-15 09:40:49
 * @FilePath: \dcy-web\packages\materials\components\MenuComponent\index.vue
 * @Description: 菜单
-->
<script setup lang="ts">
import MenuItem from './item.vue'

const props = defineProps({
  menuList: {
    type: Array,
    default: () => [],
  },
})

const appStore = useAppStore()
const route = useRoute()

const userInfo = localStorage.getItem('menuInfo') ? JSON.parse(localStorage.getItem('menuInfo')) : {}
// 当前选中的menu key
const defaultSelectedKey = computed(() => [appStore.$state?.menuKey || userInfo?.menuList?.[0]?.id])
const selectKey = ref(defaultSelectedKey.value)
const menuData = computed(() => {
  return filterMenu(props.menuList)
})

/**
 * menu 切换事件
 * @param e num key
 */
const onMenuItemClick = (e: string) => {
  appStore.updateSettings({ ...appStore.$state, menuKey: e })
}

watch(
  () => defaultSelectedKey.value,
  (val) => {
    selectKey.value = val
  },
)

// 监听路由触发菜单高亮
watchEffect(() => {
  const flatMenu = flattenMenu(filterMenu(props.menuList)).filter((item) => {
    // 去除目录级菜单
    return item?.jsonField ? !JSON.parse(item?.jsonField)?.isDirectory : false
  })

  const selectId = flatMenu?.find(item => item.pathUrl === route.path)?.id
  if (selectId)
    onMenuItemClick(selectId)
})

// 扁平化
function flattenMenu(menu: Record<string, any>[]) {
  const result: Record<string, any>[] = []

  function recurse(items: Record<string, any>[]) {
    items.forEach((item) => {
      result.push(item)

      if (item.children && item.children.length > 0)
        recurse(item.children)
    })
  }

  recurse(menu)
  return result
}

function filterMenu(data: Record<string, any>[]) {
  return data.reduce((acc, item) => {
    // 检查当前项的 pathUrl 字段是否以 / 开头
    const hasValidPathUrl = item.pathUrl && item.pathUrl.startsWith('/')

    // 递归检查子项
    const filteredChildren = item.children ? filterMenu(item.children) : []

    // 如果当前项有有效的 pathUrl 或有过滤后的子项，则保留当前项
    if (hasValidPathUrl || filteredChildren.length > 0) {
      acc.push({
        ...item,
        children: filteredChildren,
      })
    }

    return acc
  }, [])
}
</script>

<template>
  <div>
    <a-menu
      v-model:selected-keys="selectKey" class="menu-wrapper" theme="dark" :default-open-keys="selectKey"
      auto-open
      @menu-item-click="onMenuItemClick"
    >
      <MenuItem v-for="(menu,) in menuData" :key="menu.id" :m-key="menu.id" :menu="menu" />
    </a-menu>
  </div>
</template>

<style lang="less" scoped>
.menu-wrapper {
  // background: #3F79FE;
  --at-apply: bg-[#3F79FE] flex-1 overflow-auto;

  :deep(.arco-menu-item) {
    background: #3F79FE;
  }

  :deep(.arco-menu-inline-header) {
    background: #3F79FE;
    color: #fff;
  }

  :deep(.arco-menu-item:hover) {
    background-color: #1E61FA;
  }

  :deep(.arco-menu-selected) {
    background-color: transparent;
  }

  :deep(.arco-menu-vertical .arco-menu-pop-header) {
    background-color: transparent;
  }

  :deep(.arco-menu-selected .arco-icon) {
    color: #FFF;
  }
}

:deep(.arco-menu-dark .arco-menu-item.arco-menu-selected) {
  color: #FFF;
  background-color: #1E61FA !important;
}

:deep(.arco-menu-pop) {
  background-color: transparent;
}

// :deep(.arco-menu-title) {
//   display: none;
// }
</style>
