<script lang="ts" setup>
import { Message } from '@arco-design/web-vue'
import type { PlatForm } from '@dcyjs/utils/constText'
import { PlatFormOptions, _HOSTMAP } from '@dcyjs/utils/constText'
import SwitchPlatFormModal from '@dcyjs/materials/components/SwitchPlatFormModal.vue'

const appStore = useAppStore()
const userStore = useUserStore()
const accountStore = useUserAccountStore()
const runtimeConfig: any = useRuntimeConfig()
const { env } = runtimeConfig?.public
// 跳转至官网
const hostUrl = `${_HOSTMAP?.[env]?.website}`
const appCode = computed(() => appStore.appCode) // study,teach,admin

const toPersonalPage = () => {
  navigateTo('/personal')
}

const avatar = computed(() => {
  return accountStore?.iconUrl || 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/teacher_avatar.png'
})

const platformVisible = ref(false)
// 除当前工作台外可切换的工作台列表
const platformList = computed(() => {
  const arr = [] as PlatForm[]
  PlatFormOptions.filter(item => item.value !== appCode.value)?.forEach((item) => {
    if (userStore?.platformList?.some(i => item.name === i.name))
      arr.push(item)
  })
  return arr
})

// 切换端
function handleSwitchApp() {
  if (platformList.value.length > 1) {
    platformVisible.value = true
  }
  else if (platformList.value.length > 0) {
    navigateTo({
      path: `${_HOSTMAP?.[env]?.[platformList.value[0].value]}`,
      query: {
        token: getToken(),
      },
    }, { external: true })
  }
}

function handleLogout() {
  Message.success('退出成功')
  const cb = () => {
    navigateTo(
      {
        path: hostUrl,
        query: { logout: 1 },
      },
      { external: true },
    )
  }
  userStore.logout(cb)
}
</script>

<template>
  <div class="navbar">
    <a-dropdown trigger="click">
      <div class="user-avatar">
        <a-avatar
          :size="32"
          :style="{ marginRight: '0.63rem', cursor: 'pointer' }"
        >
          <img alt="avatar" :src="avatar">
        </a-avatar>
        <div class="mr-[0.5rem]">
          {{ userStore.accountName }}
        </div>
        <icon-down class="mr-[1.25rem]" />
      </div>
      <template #content>
        <a-doption>
          <a-space @click="toPersonalPage">
            <icon-user />
            <span>
              个人信息
            </span>
          </a-space>
        </a-doption>
        <a-doption v-if="platformList.length > 0" class="nav-custom-dropdown-option">
          <NuxtLink v-if="platformList.length > 1" @click="handleSwitchApp">
            <a-space>
              <icon-swap />
              <span>
                切换工作台
              </span>
            </a-space>
          </NuxtLink>
          <NuxtLink v-else @click="handleSwitchApp">
            <a-space>
              <icon-swap />
              <span>
                切换至{{ platformList[0].name }}
              </span>
            </a-space>
          </NuxtLink>
        </a-doption>
        <a-doption>
          <a-space @click="handleLogout">
            <icon-export />
            <span>
              退出登录
            </span>
          </a-space>
        </a-doption>
      </template>
    </a-dropdown>

    <!-- 切换工作台弹窗 -->
    <SwitchPlatFormModal :visible="platformVisible" :platform-list="platformList" @on-close="platformVisible = false" />
  </div>
</template>

<style scoped lang="less">
  .navbar {
    --at-apply: flex justify-end bg-[#FFF] h-[3.75rem];
    box-shadow: 0.06rem 0.25rem 0.25rem 0 #0000000f;
  }
  .right-side {
    padding-right: 1.25rem;
  }

.user-avatar {
  --at-apply: flex items-center cursor-pointer;
}
.arco-dropdown-option{
  --at-apply: block;
  :deep(.arco-dropdown-option-content){
    --at-apply: block w-full;
    .arco-space{
      --at-apply: w-full;
    }
  }
}
</style>

<style lang="less">
  .message-popover {
    .arco-popover-content {
      margin-top: 0;
    }
  }
</style>
