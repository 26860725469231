<script setup lang="ts">
import { getToken } from '@dcyjs/utils/auth'
import type { Environment, PlatForm, UserType } from '@dcyjs/utils/constText'
import { _HOSTMAP } from '@dcyjs/utils/constText'

const props = withDefaults(defineProps<{
  visible: boolean
  platformList: PlatForm[]
}>(), {
  visible: false,
  platformList: () => [],
})
const emits = defineEmits(['onClose'])

const config = useRuntimeConfig()

function handleJumpPage(type: UserType) {
  const env = config?.public?.env as Environment
  const hostConfig = _HOSTMAP[env]
  const toPath = hostConfig[type]
  navigateTo({
    path: toPath,
    query: {
      token: getToken(),
    },
  }, {
    external: true,
  })
}
</script>

<template>
  <a-modal
    :visible="props.visible" :footer="false" :mask-closable="false"
    modal-class="platform-info-modal" @cancel="emits('onClose')"
  >
    <template #title>
      <div />
    </template>
    <div class="flex items-center justify-center text-center">
      <div
        v-for="platform in props.platformList" :key="platform.value" class="img-box"
        @click="handleJumpPage(platform.value)"
      >
        <img :src="platform.image" class="mb-[2rem] w-[9.25rem]">
        <div class="text-[1.5rem] fw-700 text-[#0F172A]">
          {{ platform?.name }}
        </div>
      </div>
    </div>
  </a-modal>
</template>

<style lang="less" scoped>
.platform-info-modal {
  .active-box {
    --at-apply: bg-[#F1F5F9];
  }

  .img-box {
    --at-apply: cursor-pointer mx-[1rem] py-[3rem] px-[2rem] rounded-[1rem];

    &:hover {
      .active-box;
    }
  }
}
</style>

<style lang="less">
.platform-info-modal {
  width: auto;
  // .arco-modal-header {
  //   height: auto !important;
  // }
  .arco-modal-body {
    padding: 0 2.5rem 2.5rem 2.5rem !important;
  }
}
</style>
