<!--
 * @Author: 熊志伟
 * @Date: 2023-12-06 17:30:02
 * @LastEditors: huang liang miao 1127255342@qq.com
 * @LastEditTime: 2025-03-04 08:46:51
 * @FilePath: \dcy-web\apps\admin\layouts\default.vue
 * @Description: 默认layout
-->
<script lang="ts" setup>
import { _HOSTMAP } from '@dcyjs/utils/constText'

const runtimeConfig: any = useRuntimeConfig()
const { env } = runtimeConfig?.public
// 跳转至官网
const hostUrl = `${_HOSTMAP?.[env]?.website}`

function toHome() {
  navigateTo(
    { path: hostUrl },
    { external: true },
  )
}
</script>

<template>
  <a-layout class="wrapper">
    <a-layout>
      <a-layout-sider
        theme="dark" class="h-[100vh] w-[13.75rem]!"
      >
        <div class="logo">
          <img class="nav-logo" src="https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/logo-new.png" @click="toHome">
          <!-- <span>后台管理</span> -->
        </div>
        <Menu />
      </a-layout-sider>
      <a-layout-content>
        <a-layout-header>
          <ClientOnly>
            <NavBar />
          </ClientOnly>
        </a-layout-header>
        <div class="layout-content-wrapper">
          <slot name="breadcrumb">
            <Breadcrumb />
          </slot>
          <slot />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<style scoped lang="less">
.wrapper {
  height: 100vh;
  overflow-y: auto;

  :deep(.arco-layout-sider) {
    --at-apply: bg-[#3F79FE];
  }

  :deep(.arco-layout-content) {
    --at-apply: h-[100vh] flex flex-col;
  }

  .logo{
    --at-apply: text-white relative;
    span{
      --at-apply: absolute right-[20px] top-50%;
      transform: translateY(-50%);
    }
  }
  .nav-logo {
    --at-apply: inline-block h-18 w-full object-contain cursor-pointer;
  }

  .layout-content-wrapper {
    --at-apply: p-[16px] flex-1 flex flex-col overflow-auto;
    // height: calc(100vh - 4.5rem);
    :deep(.layout-content){
      --at-apply: rounded-[4px];
      .picker{
        --at-apply: cursor-pointer;
        .arco-picker-input input{
          --at-apply: cursor-pointer;
        }
      }
    }
  }
  :deep(.pagination){
    --at-apply: flex justify-end mt-[20px];
  }
  // :deep(.menu-wrapper) {
  //   --at-apply: bg-[#2D2C88];

  //   .arco-menu-item{
  //     --at-apply: bg-[#2D2C88];
  //   }

  //   .arco-menu-inline-header{
  //     --at-apply: bg-[#2D2C88];
  //   }

  //   .arco-menu-item:hover{
  //     --at-apply: bg-[#3F79FE];
  //   }
  //   .arco-menu-item.arco-menu-selected {
  //     background: #3F79FE!important;
  //   }
  // }
}
</style>
