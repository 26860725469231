<!--
 * @Author: 熊志伟 xiongzhiwei
 * @Date: 2024-04-12 15:37:56
 * @LastEditors: 熊志伟 xiongzhiwei
 * @LastEditTime: 2025-02-15 10:43:54
 * @FilePath: \dcy-web\apps\admin\components\Menu.vue
 * @Description: 菜单
-->
<script lang="ts" setup>
const userStore = useUserStore()

const menuList = computed(() => {
  return userStore?.menuList
})
</script>

<template>
  <MenuComponent :menu-list="menuList" />
</template>
